import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import AuthContextProvider from "./Context/AuthContext";
import { Provider } from "react-redux";
import { store } from "@cleeng/mediastore-sdk";
import { ThemeProvider } from "styled-components";

ReactDOM.render(
  <Provider store={store}>
    {/* <ThemeProvider
      theme={
        {
          //   backgroundColor: "#0c0722",
          //   cardColor: "#5c0722",
          //   errorColor: "#ff0000",
          //   fontColor: "#94ffb0",
          //   loaderColor: "#5589c7",
          //   logoUrl:
          //     "https://img.freepik.com/free-vector/colorful-letter-gradient-logo-design_474888-2309.jpg",
          //   primaryColor: "#ebb22f",
          //   secondaryColor: "#ab5497",
          //   successColor: "#141edb",
        }
      }
    > */}
    <AuthContextProvider>
      <App />
    </AuthContextProvider>
    {/* </ThemeProvider> */}
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
