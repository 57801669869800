import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";

const ReCaptchaComponent = () => {
  const [recaptchaValue, setRecaptchaValue] = useState(null);

  const handleRecaptchaChange = (value) => {
    console.log("ReCaptcha value:", value);
    setRecaptchaValue(value);
  };

  return (
    <div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          // Here you can handle the form submission, including sending the recaptchaValue to your backend
          console.log("Form submitted with ReCaptcha value:", recaptchaValue);
        }}
      >
        <label htmlFor="name">Name:</label>
        <input type="text" id="name" name="name" required />
        <br />
        <br />
        <label htmlFor="email">Email:</label>
        <input type="email" id="email" name="email" required />
        <br />
        <br />
        <ReCAPTCHA
          sitekey="6Ld3D5UpAAAAAOBQUGtg2JrPwjO3O7bgdbPlNRJ6"
          onChange={handleRecaptchaChange}
        />
        <br />
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default ReCaptchaComponent;
