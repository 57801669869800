import React from "react";
import { Checkout, Config } from "@cleeng/mediastore-sdk";
// import adyenConfiguration from "Context/adyenConfiguration";
import { useSearchParams } from "react-router-dom";

const CheckOutWithCaptcha = ({ offer }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  Config.setGoogleRecaptcha({
    sitekey: "6LdXhLoqAAAAAGNDqv61EVKOTjGpQPjVvinRybsk",
    showCaptchaOnRegister: true,
    showCaptchaOnPurchase: true,
  });
  return (
    <div>
      <h1>Checkout</h1>
      <div id="Sub-Component-Checkout">
        <Checkout offerId={offer} />
      </div>
    </div>
  );
};

export default CheckOutWithCaptcha;
