export const MenuItems = [
  {
    title: "Register",
    path: "/Register",
    cName: "dropdown-link",
  },
  {
    title: "Login",
    path: "/Login",
    cName: "dropdown-link",
  },
  {
    title: "Capture",
    path: "/Capture",
    cName: "dropdown-link",
  },
  {
    title: "CheckoutConsents",
    path: "/CheckoutConsents",
    cName: "dropdown-link",
  },
  {
    title: "CheckOutWithCaptcha",
    path: "/CheckOutWithCaptcha",
    cName: "dropdown-link",
  },
  {
    title: "CheckOutWithExtraProps",
    path: "/CheckOutWithExtraProps",
    cName: "dropdown-link",
  },
  {
    title: "CheckOutWithoutTerms",
    path: "/CheckOutWithoutTerms",
    cName: "dropdown-link",
  },
  {
    title: "Purchase",
    path: "/Purchase",
    cName: "dropdown-link",
  },
  {
    title: "PurchaseRedirect",
    path: "/PurchaseRedirect",
    cName: "dropdown-link",
  },
  {
    title: "RedeemGift",
    path: "/RedeemGift",
    cName: "dropdown-link",
  },
  {
    title: "PasswordReset",
    path: "/PasswordReset",
    cName: "dropdown-link",
  },
  {
    title: "ReCaptchaComponent",
    path: "/ReCaptchaComponent",
    cName: "dropdown-link",
  },
  {
    title: "Subscriptions",
    path: "/Subscriptions",
    cName: "dropdown-link",
  },
  {
    title: "SubscriptionSwitches",
    path: "/SubscriptionSwitches",
    cName: "dropdown-link",
  },
  {
    title: "PlanDetails",
    path: "/PlanDetails",
    cName: "dropdown-link",
  },
  {
    title: "PaymentInfo",
    path: "/PaymentInfo",
    cName: "dropdown-link",
  },
  {
    title: "TransactionList",
    path: "/TransactionList",
    cName: "dropdown-link",
  },
  {
    title: "UpdateProfile",
    path: "/UpdateProfile",
    cName: "dropdown-link",
  },
];
