import React, { useContext } from "react";
import {
  Register as RegisterComponent,
  Auth as MediastoreAuth,
} from "@cleeng/mediastore-sdk";
import { AuthContext } from "Context/AuthContext";

const Register = (props) => {
  const { isAuthenticated, setIsAuthenticated } = useContext(AuthContext);

  React.useEffect(() => {
    MediastoreAuth.isLogged();
  }, [isAuthenticated]);
  return (
    <div>
      {MediastoreAuth.isLogged() ? (
        <>
          <h2 data-cy="loggedInMessage">
            In order to see Register component you need to be logged out.{" "}
            <br></br>
            Please logout.
          </h2>
        </>
      ) : (
        <>
          <h1>Register</h1>
          <div id="Sub-Component-Register">
            <RegisterComponent onSuccess={setIsAuthenticated} />
          </div>
        </>
      )}
    </div>
  );
};

export default Register;
